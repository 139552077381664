"use client";

import React from "react";
import Title from "./Title";
import { cn, ppeikoFont } from "@/lib/utils";

const sectionData = [
  {
    title: "One search across all your loyalty programs",
    subTitle:
      "Gondola shows your points and cash member rates from every hotel in one search",
    img: "/assets/landing-page/stay-sections/1-desktop.png",
  },
  {
    title: "Seamlessly book direct",
    subTitle:
      "Gondola books directly with the hotel so you earn maximum hotel points, status nights, and the reservation shows up in your hotel app",
    img: "/assets/landing-page/stay-sections/2.png",
  },
  {
    title: "Maximize your stays with the most earnings possible",
    subTitle:
      "Earn credit card points, hotel loyalty points, and 3% Gondola Cash redeemable towards the cash cost of any hotel in the world",
    img: "/assets/landing-page/stay-sections/3.png",
  },
];

const StaySections: React.FC = () => {
  const sectionRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      ref={sectionRef}
      className="relative mt-16 flex flex-col items-center justify-start md:mt-32"
    >
      <div className="w-full px-5 lg:px-28">
        <Title>
          <span className="flex flex-wrap justify-center gap-1.5 text-center text-4xl font-normal capitalize leading-10 text-primary lg:text-[58px] lg:leading-[63.80px]">
            <span>Book Hotels </span>
            <span
              className={cn(
                "font-medium italic text-primary-gold",
                ppeikoFont.className
              )}
            >
              The Gondola Way
            </span>
          </span>
        </Title>
      </div>
      <div className="mt-6 flex w-full flex-col items-center gap-14 px-3 lg:mt-16 lg:gap-24 lg:px-0 xl:px-28">
        {sectionData.map((data, index) => (
          <div
            key={index}
            className={cn(
              "flex w-full flex-col-reverse items-center justify-start gap-4 lg:gap-10",
              index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
            )}
          >
            <img
              src={data.img}
              alt={data.img}
              className={cn(
                "w-fit object-contain lg:h-[500px] lg:min-h-[500px]",
                index === 1 ? "lg:pr-6" : ""
              )}
            />
            <div className="flex w-full flex-col items-center gap-4 px-2 lg:items-start lg:gap-8 lg:px-10">
              <span className="text-center text-2xl font-medium text-primary md:text-3xl lg:text-left lg:text-5xl">
                {data.title}
              </span>
              <span className="text-center text-base text-gondola-blue-1 md:text-lg lg:text-left lg:text-2xl">
                {data.subTitle}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaySections;
